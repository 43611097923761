import React, { useState } from 'react';
import './Form.css';
import { FiSend } from 'react-icons/fi';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import app from '../../../firebase/firebase.js';


const Form = () => {

    const d = new Date();
    const curDate = (d.getMonth() + 1) + "-" + d.getDate() + "-" +  d.getFullYear();
    const curTime = new Date().getHours() + ":" + (new Date().getMinutes() < 10 ? "0" : "") + new Date().getMinutes();


    const [formData, setFormData] = useState({
        Name: '',
        Email: '',
        Message: '',
        Date: curDate,
        Time:curTime,
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const sendMessage = async (e) => {
        e.preventDefault();

        const db = getFirestore(app);

        document.querySelector(".notifications-container").style.display = "flex";

        setTimeout(() => {
            document.querySelector(".notifications-container").style.display = "none";
        }, "5000");

        try {
            await addDoc(collection(db, 'clientMessage'), formData);
            console.log('Message sent successfully!');
            setFormData({
                Name: '',
                Email: '',
                Message: '',
                Date: curDate,
                Time:curTime,
            });
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    return (
        <div className="contacts-form">
            <form onSubmit={sendMessage}>
                <div className="input-container">
                    <label htmlFor="Name" className="jss98">
                        Name
                    </label>
                    <input
                        placeholder="Anas Rafia"
                        type="text"
                        name="Name"
                        className="form-input jss96"
                        value={formData.Name}
                        onChange={handleChange} required
                    />
                </div>
                <div className="input-container">
                    <label htmlFor="Email" className="jss98">
                        Email
                    </label>
                    <input
                        placeholder="Anas@rafia.com"
                        type="email"
                        name="Email"
                        className="form-input jss96"
                        value={formData.Email}
                        onChange={handleChange} required
                    />
                </div>
                <div className="input-container">
                    <label htmlFor="Message" className="jss98">
                        Message
                    </label>
                    <textarea
                        placeholder="Type your message...."
                        type="text"
                        name="Message"
                        className="form-message jss97"
                        value={formData.Message}
                        onChange={handleChange} required
                    ></textarea>
                </div>
                <div className="submit-btn">
                    <button type="submit" className="jss101">
                        <p className='m-2'>
                            Send <FiSend className="iconSend" />
                        </p>
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Form;
