import './styles.css'

export default function App() {

  const skills = [
    { name: 'React', icon: 'https://i.ibb.co/rkpw7nK/react-Icon.png' },
    { name: 'Laravel', icon: 'https://i.ibb.co/zQNpcsj/985px-Laravel-svg.png' },
    { name: 'MongoDB', icon: 'https://i.ibb.co/20JKB4x/mongo.png' },
    { name: 'Firebase', icon: 'https://i.ibb.co/jDFMfJm/firebase.png' },
    { name: 'MYSQL', icon: 'https://i.ibb.co/qk4Lm8t/mysql.png' },
    { name: 'Python', icon: 'https://i.ibb.co/GxV33qx/4990671.png' },
    { name: 'JavaScript', icon: 'https://i.ibb.co/yQcHNP9/javascript-logo-javascript-icon-transparent-free-png.webp' },
    { name: 'PHP', icon: 'https://i.ibb.co/x2Vz8pM/5968332.png' },
  ];

  return (
    <div style={{backgroundColor:"#212121"}} className='w-100'>
      <div className="container" style={{ backgroundColor: "#212121", padding: "20px"}}>

        <h1 style={{ color: "rgb(245, 101, 57)", textAlign: "center" }}>Skills</h1>

        <div className="row text-center justify-content-around mt-5">

          {skills.map((skill, index) => (
            <div key={index} className="col-lg-3 col-md-3 col-sm-4 col-6 mb-4">
              <div className="skill d-flex justify-content-center">
                <img className="img-fluid rounded" src={skill.icon} alt={skill.name} style={{ maxWidth: "100%", height: "auto" }} />
              </div>
            </div>
          ))}

        </div>

      </div>
    </div>


  );
}
