import About from './Component/About/About';
import Contact from './Component/Contact/Contact';
import Header from './Component/Header/Header';
import Home from './Component/Home/Home';
import Resume from './Component/Resume/Resume';
import Skills from './Component/Skills/Skills';
import MessageAlert from './Component/MessageAlert/MessageAlert';
import './App.css'


function App() {
  return (
    <div className="App">
      <MessageAlert/>
      <Header/>
      <Home/>
      <About/>
      <Resume/>
      <Skills/>
      <Contact/>
    </div>
  );
}

export default App;
