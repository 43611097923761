import React from 'react'
import './About.css'
import AboutBody from './AboutBody/AboutBody'

const About = () => {
  return (
    <div id='About' className='about'>
        
        <div class="line-styling">
            <div class="style-circle" style={{backgroundColor:"rgb(245, 101, 57)"}}></div>
            <div class="style-circle" style={{backgroundColor:"rgb(245, 101, 57)"}}></div>
            <div class="style-line" style={{backgroundColor:"rgb(245, 101, 57)"}}></div>
        </div>

        <AboutBody/>

    </div>
  )
}

export default About