import React from 'react'
import { FaLinkedin, FaGithub, FaTwitter, FaYoutube, FaInstagram } from "react-icons/fa";

const LeftSide = () => {
    return (
        <div className='leftSide'>

            <div className='landing--container'>
                <img className='landing--img' src="https://i.ibb.co/q1ynwZs/cropped-DSC-13133.jpg" alt="anas rafia" />
            </div>

            <div className='socialMedia'>

                <a href='https://www.linkedin.com/in/anas-rafia-a31275244'><FaLinkedin className="icon_social_media" /></a>
                <a href='https://github.com/anassrafia/'><FaGithub className="icon_social_media" /></a>
                <a href='https://twitter.com/anas__rafia'><FaTwitter className="icon_social_media" /></a>
                <a href='https://www.youtube.com/@anas_rafia/videos'><FaYoutube className="icon_social_media" /></a>
                <a href='https://www.instagram.com/rafia.anass/'><FaInstagram className="icon_social_media" /></a>


            </div>

        </div>
    )
}

export default LeftSide
