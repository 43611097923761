import React from 'react'
import './Contact.css'
import Form from './Form/Form.js'
import ContDetails from './ContDetails/ContDetails.js'

const Contact = () => {
  return (
    <div id='Contact'>
      <div style={{marginTop: "5%"}}>
        <h1 className='titleContact' style={{color: "rgb(245, 101, 57)"}}>
            Contacts
        </h1>

        <section className='contacts-body'>
            <Form/>
            <ContDetails/>
        </section>

        <div className="footer" style={{backgroundColor:"rgb(33, 33, 33)", marginTop:"50px"}}>
            <p style={{color:"rgb(234, 234, 234)"}}>Made with<span style={{color:"rgb(245, 101, 57)", margin:"0px 0.5rem -1rem"}}>❤</span>by Anas Rafia</p>
        </div>
        </div>

    </div>
  )
}

export default Contact