import React from 'react';
import './Resume.css';
import desktopOne from '../../media/desktopOne.png';
import dbResume from './Db.json'
import { IoSchoolOutline } from "react-icons/io5";



const Resume = () => {

    return (
        <div id="Resume">
            <section className='resumeSection'>
                <h1 className='resumeTitle'>Education</h1>

                <div className='resumeContainer'>
                    <img className='edcImg_desktopOne' src={desktopOne} alt='desktop anas rafa' />
                    <div className='educationContainer'>
                        {Object.values(dbResume).map((data, i) => (
                            <div className='edcuCard' key={i}>
                                <div className='edImg'>
                                    <IoSchoolOutline  />
                                </div>
                                <div className='edDesc'>
                                    <h2>{data.year}</h2>
                                    <p>{data.schName}</p>
                                    <p>{data.degree}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Resume;
