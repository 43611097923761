import React from 'react'
import './RightSide.css'

const RightSide = () => {
    return (
        <div className='rightSide'>
        <div className="lcr--content" style={{color: "rgb(234, 234, 234)"}}>
                <h6 className="">FULL-STACK DEVELOPER</h6>
                <h1 className="">Anas Rafia</h1>
                <p className="">specializes in developing cutting-edge web applications using ReactJs for the frontend
                 and PHP with MongoDB, SQL, and Python for the backend.
                  His expertise lies in creating modern, efficient, and seamlessly integrated solutions for a dynamic user experience. 
                </p>
                <div className="lcr-buttonContainer">
                    <a href="/anasrafiaCV.pdf" target="_blank" rel="noreferrer" className="">
                        <button className="MuiButtonBase-root MuiButton-root MuiButton-text jss227" tabindex="0" type="button">
                            <span className="MuiButton-label">my resume!</span><span className="MuiTouchRipple-root"></span>
                        </button>
                    </a>
                    <a spy="true" duration="2000" href="/#Contact" className="">
                        <button className="MuiButtonBase-root MuiButton-root MuiButton-text jss228" tabindex="0" type="button">
                            <span className="MuiButton-label">Contact</span>
                            <span className="MuiTouchRipple-root"></span>
                        </button>
                    </a>
                    </div>
                </div>
        </div>
    )
}

export default RightSide