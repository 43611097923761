import React from 'react'
import './Header.css'
import { HiBars3 } from "react-icons/hi2";

const Header = () => {

  const showToogle = () =>{
    var toogleSide = document.querySelector(".ToogleSide");
    var logo = document.querySelector(".logo");
    var iconBars = document.querySelector(".iconBars");
    toogleSide.style.display = "block";
    logo.style.display = "none";
    iconBars.style.display = "none";
    document.body.style.overflow = "hidden";
  }

  return (
    <header>

        <img class="logo" src="https://i.ibb.co/Hd7rN9p/logo.png" alt="anas rafia"/>
        
        <div class="iconBars" onClick={showToogle}>
          <HiBars3/>
        </div>

    </header>
  )
}

export default Header