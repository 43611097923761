import React from 'react'
import './Home.css'
import LeftSide from './LeftSide/LeftSide'
import RightSide from './RightSide/RightSide'
import ToogleSide from './ToogleSide/ToogleSide'


const Home = () => {
  return (
    <div className='Container'>

        <LeftSide/>

        <RightSide/>

        <ToogleSide/>

    </div>
  )
}

export default Home