import React from 'react'
import './MessageAlert.css'
import { FaCheckCircle } from "react-icons/fa";


const MessageAlert = () => {
    return (
        <div className="notifications-container">
            <div className="success">
                <div className="ceck">
                    <div className="flex-shrink-0">
                        <FaCheckCircle className="flex-shrink-1 " />
                    </div>
                    <div className="success-prompt-wrap">
                        <p className="success-prompt-heading">Message sent successfully</p>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default MessageAlert