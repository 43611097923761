import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';


const firebaseConfig = {
  apiKey: "AIzaSyAWkKcOwiqGvVeACk2Jekt5LgfcCsABLZM",
  authDomain: "anasrafia-site.firebaseapp.com",
  projectId: "anasrafia-site",
  storageBucket: "anasrafia-site.appspot.com",
  messagingSenderId: "328301537756",
  appId: "1:328301537756:web:a7a3aa76163a3057438719",
  measurementId: "G-HWR6S6XKJX"
};


const app = initializeApp(firebaseConfig);
const authInstance = getAuth(app);

export default app;
export { authInstance };