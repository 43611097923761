import React from 'react'
import './Login.css'

import app from '../../firebase/firebase';
import { getFirestore, collection, onSnapshot } from 'firebase/firestore';
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';


const Login = () => {

    const userName = useRef()
    const Password = useRef()
    const navigate = useNavigate()

    const [info, setInfo] = useState([]);

    useEffect(() => {
        const unsubscribe = fetchData();
        return () => unsubscribe();
    }, []);

    const fetchData = () => {
        const db = getFirestore(app);
        return onSnapshot(collection(db, "loginAdmin"), (querySnapshot) => {
            const newData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setInfo(newData);
        }, []);
    }

    const loginHandler = () => {
        info.forEach(data => {
            if(data.username === userName.current.value && data.password === Password.current.value){
                localStorage.setItem("KLJDJJSKJDHklj56454@@##$$%---**/asdkja54557---loginSession", true);
                navigate("/adminDashboard")
            }
            else{
                alert("user not found!")
            }
        });
    }


    return (
        <div className="main m-auto mt-5">
            <input type="checkbox" id="chk" aria-hidden="true" />

            <div className="login">
                <form className="form">
                    <label className='text-center' for="chk" aria-hidden="true">Log in</label>
                    <input ref={userName} className="input" type="email" name="email" placeholder="Email" required="" />
                    <input ref={Password} className="input" type="password" name="pswd" placeholder="Password" required="" />
                    <button type='button' onClick={loginHandler}>Log in</button>
                </form>
            </div>
        </div>
    )
}

export default Login