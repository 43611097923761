import React from 'react'
import './ToogleSide.css'
import { IoHomeSharp, IoDocumentText } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { MdCall } from "react-icons/md";
import { SiGooglenews } from "react-icons/si";



const ToogleSide = () => {

    const closeToogle = () =>{
        var toogleSide = document.querySelector(".ToogleSide");
        var logo = document.querySelector(".logo");
        var iconBars = document.querySelector(".iconBars");
        toogleSide.style.display = "none";
        logo.style.display = "block";
        iconBars.style.display = "block";
        document.body.style.overflow = "auto";
    }

    return (
        <div className='ToogleSide' onClick={closeToogle}>
            <div className='ToogleList'>

                <div className='close' onClick={closeToogle}>
                    <IoMdClose />
                </div>

                <div className='list'>
                    <a href='/'><li><IoHomeSharp />Home</li></a>

                    <a href='/#About'><li><FaUser />About</li></a>

                    <a href='/#Resume'><li><IoDocumentText />Resume</li></a>

                    <a href='/News'><li><SiGooglenews />News</li></a>

                    <a href='/#Contact'><li><MdCall />Contact</li></a>

                </div>
            </div>
        </div>
    )
}

export default ToogleSide