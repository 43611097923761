import React from 'react'
import './ContDetails.css'
import { MdAlternateEmail } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import { IoLocationOutline } from "react-icons/io5";
import { FaTwitter, FaGithub, FaLinkedinIn, FaInstagram, FaYoutube, FaRedditAlien, FaStackOverflow, FaCodepen, FaGitlab } from "react-icons/fa";


const ContDetails = () => {
    return (
        <div className='contDetails'>

            <div className='contactInfo'>

                <a className="details" href="mailto:anasrafiaoff@gmail.com">
                    <div className="jss100"><MdAlternateEmail /></div>
                    <p style={{ color: "rgb(234, 234, 234)" }}>anasrafiaoff@gmail.com</p>
                </a>

                <a href="tel:+212641390364" className="details">
                    <div className="jss100"><FiPhone /></div>
                    <p style={{ color: "rgb(234, 234, 234)" }}>+212641390364</p>
                </a>

                <a target='_blank' rel="noreferrer" href='https://maps.app.goo.gl/awZsZjNi5rr7bfSMA' style={{ cursor: "alias" }} className="details">
                    <div className="jss100"><IoLocationOutline /></div>
                    <p style={{ color: "rgb(234, 234, 234)" }}>Hassan, Rabat, Morocco</p>
                </a>


                
            <div className='socialMediaIcons2'>
                        <a href="https://twitter.com/anas__rafia" target="_blank" rel="noreferrer" className="jss99"><FaTwitter /></a>
                        <a href="https://github.com/anassrafia/" target="_blank" rel="noreferrer" className="jss99"><FaGithub /></a>
                        <a href="https://www.linkedin.com/in/anas-rafia-a31275244" target="_blank" rel="noreferrer" className="jss99"><FaLinkedinIn /></a>
                        <a href="https://www.instagram.com/rafia.anass/" target="_blank" rel="noreferrer" className="jss99"><FaInstagram /></a>
                        <a href="https://www.youtube.com/@anas_rafia/videos" target="_blank" rel="noreferrer" className="jss99"><FaYoutube /></a>
                        <a href="https://www.reddit.com/user/SeparateCucumber2818" target="_blank" rel="noreferrer" className="jss99"><FaRedditAlien /></a>
                        <a href="https://stackoverflow.com/users/20693034/rafia-anas" target="_blank" rel="noreferrer" className="jss99"><FaStackOverflow /></a>
                        <a href="https://gitlab.com/anassrafia2005" target="_blank" rel="noreferrer" className="jss99"><FaGitlab /></a>
                        <a href="https://codepen.io/rafia-anas" target="_blank" rel="noreferrer" className="jss99"><FaCodepen /></a>
                </div>
                
            </div>


        </div>
    )
}

export default ContDetails