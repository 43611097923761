import app from '../firebase/firebase'; // Update the path to your firebaseConfig.js file
import { getFirestore, collection, onSnapshot, doc, deleteDoc } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { FaTrash } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const Admin = () => {

    document.querySelector("body").onload = () =>{
        checkSession()
    }

    const navigate = useNavigate()

    const checkSession = () => {
        const mySession = localStorage.getItem("KLJDJJSKJDHklj56454@@##$$%---**/asdkja54557---loginSession")
        if (mySession === "true") {
            alert("welcome admin");
        }
        else {
            navigate("/admin")
        }
    }


    const [info, setInfo] = useState([]);

    useEffect(() => {
        const unsubscribe = fetchData();
        return () => unsubscribe();
    }, []);

    // Fetch the required data using Firestore
    const fetchData = () => {
        const db = getFirestore(app);
        return onSnapshot(collection(db, "clientMessage"), (querySnapshot) => {
            const newData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setInfo(newData);
        });
    }

    // Handle document deletion
    const handleDelete = async (id) => {
        try {
            const db = getFirestore(app);
            await deleteDoc(doc(db, "clientMessage", id));
            console.log("Document successfully deleted!");
        } catch (error) {
            console.error("Error deleting document: ", error);
        }
    }

    const logOut = () =>{
        localStorage.clear();
        navigate("/admin");
    }

    // Display the result on the page
    return (
        <div id='myDashboard'>
            <h4 className='text-black text-center mt-3'>Messages</h4>

            <button onClick={logOut} style={{position:"absolute", right:"20px", top:"20px"}} className='btn btn-secondary'>Log out</button>

            <table className='table w-75 shadow border m-auto mt-4'>

                <thead>
                    <tr className='text-center'>
                        <th className='border'>Name</th>
                        <th className='border'>Email</th>
                        <th className='border'>Message</th>
                        <th className='border'>Time</th>
                        <th className='border'>Action</th>
                    </tr>
                </thead>

                <tbody>

                    {info.map((data, index) => (
                        <Frame key={index} {...data} handleDelete={handleDelete} />
                    ))}

                </tbody>

            </table>
        </div>
    );
}

// Define how each display entry will be structured
const Frame = ({ id, Name, Email, Message, Time, Date, handleDelete }) => {

    const ReadMore = (Message) => {
        alert(Message)
    }

    return (
        <tr className='text-center'>
            <td className='border'>{Name}</td>
            <td className='border'>{Email}</td>
            <td className='border d-flex'>
                <div style={{ overflow: "hidden", width: "18rem", height: "30px", margin: "auto" }}>
                    {Message}
                </div>
                <button onClick={() => ReadMore(Message)} className='btn btn-primary'>Read more</button>
            </td>
            <td className='border'>
                <div className='d-flex justify-content-around'>
                    <p>{Date}</p>
                    <p>{Time}</p>
                </div>
            </td>
            <td>
                <button className='btn btn-danger d-flex justify-content-center align-items-center m-auto p-2' onClick={() => handleDelete(id)}><FaTrash /></button>
            </td>
        </tr>
    );
}

export default Admin;
